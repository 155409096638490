import $axios from "../http"

class ContactsService {
   static fetchContacts = async (query = '') => {
      const { data } = await $axios.get('contact/get' + query)
      return data
   }

   static fetchContactData = async (id) => {
      const { data } = await $axios.get(`contact/info?id=${id}`)
      return data
   }
}

export default ContactsService