import { 
   fetchContactDataFinished, 
   fetchContactDataStarted, 
   fetchContactDataSuccess, 
   fetchContactsFinished, 
   fetchContactsStarted, 
   fetchContactsSuccess, 
   setContacts 
} from "."
import ContactsService from "../../../services/contactsService"
import { setContactsFilterCursor } from "../contactsFilter"

export const fetchContacts = (query, fetchNextPortion = false) => async (dispatch, getState) => {
   try {
      dispatch(fetchContactsStarted())
      if (!fetchNextPortion) {
         dispatch(setContacts([]))
         dispatch(setContactsFilterCursor(null))
      }

      const cursor = getState().contactsFilter.cursor
      if (fetchNextPortion && !cursor) return

      const data = await ContactsService.fetchContacts(query)

      if (!data.error) {
         dispatch(fetchContactsSuccess(data.data.clients))
         dispatch(setContactsFilterCursor(data.data.next_cursor))
      }
   } catch (err) {
      console.log(err)
   } finally {
      dispatch(fetchContactsFinished())
   }
}

export const fetchContactData = (id) => async (dispatch) => {
   try {
      dispatch(fetchContactDataStarted())
      const data = await ContactsService.fetchContactData(id)

      if (!data.error) {
         dispatch(fetchContactDataSuccess(data.data))
      } else {
         alert(data.data.error)
      }
   } catch (err) {
      alert('Не удалось получить данные контакта.')
   } finally {
      dispatch(fetchContactDataFinished())
   }
}