import { AVITO, INSTAGRAM, TELEGRAM, VK, WHATSAPP } from "./channelNames";

const voiceMessageConstraints = {
   max: {
      [WHATSAPP]: 3600,
      [VK]: 3600,
      [TELEGRAM]: 7200,
      [INSTAGRAM]: 60,
      [AVITO]: 60,
      DEFAULT: 60
   },
   min: 3
}

export default voiceMessageConstraints