import React, { Component } from 'react'

import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import Timer from './Timer'

export default class VoiceRecorder extends Component {
   constructor(props) {
      super(props)

      this.state = {
         recordState: 'stop',
      }
   }

   start = () => {
      this.setState({
         recordState: RecordState.START,
      })
      this.props.setTimer(0)
   }

   stop = () => {
      this.setState({
         recordState: RecordState.STOP,
      })
   }

   //audioData contains blob and blobUrl
   onStop = (audioData) => {
      this.props.setAudioTransmit(audioData)
   }

   render() {
      const { recordState } = this.state
      return (
         <div style={{ display: 'inline' }} disabled={!this.props.micActivated}>
            <div style={{ display: 'none' }}>
               <AudioReactRecorder state={recordState} onStop={this.onStop} />
            </div>
            <button className={'recorder-button'}
               disabled={this.props.currentDialog?.dialog_channelStatus !== 'active'}
               // onMouseDown={this.start}
               // onMouseUp={this.stop}
               onClick={
                  this.state.recordState === 'stop' ? this.start : this.stop
               }
               style={{
                  width: '25px',
                  height: '25px',
                  cursor: 'pointer',
                  position: 'relative',
                  border: 'none',
                  backgroundColor: '#F8F8F8',
               }}
            >
               {this.state.recordState === 'start' ? (
                  <Timer
                     timer={this.props.timer}
                     setTimer={this.props.setTimer}
                  />
               ) : (
                  <></>
               )}
               <svg
                  opacity=".3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M12 2.75C10.2051 2.75 8.75 4.20507 8.75 6V12C8.75 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12V6C15.25 4.20507 13.7949 2.75 12 2.75ZM7.25 6C7.25 3.37665 9.37665 1.25 12 1.25C14.6234 1.25 16.75 3.37665 16.75 6V12C16.75 14.6234 14.6234 16.75 12 16.75C9.37665 16.75 7.25 14.6234 7.25 12V6Z"
                     fill="#252525"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M5 9.75C5.41421 9.75 5.75 10.0858 5.75 10.5V12C5.75 15.4518 8.54821 18.25 12 18.25C15.4518 18.25 18.25 15.4518 18.25 12V10.5C18.25 10.0858 18.5858 9.75 19 9.75C19.4142 9.75 19.75 10.0858 19.75 10.5V12C19.75 16.2802 16.2802 19.75 12 19.75C7.71979 19.75 4.25 16.2802 4.25 12V10.5C4.25 10.0858 4.58579 9.75 5 9.75Z"
                     fill="#252525"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M12 1.75C12.4142 1.75 12.75 2.08579 12.75 2.5V5.5C12.75 5.91421 12.4142 6.25 12 6.25C11.5858 6.25 11.25 5.91421 11.25 5.5V2.5C11.25 2.08579 11.5858 1.75 12 1.75Z"
                     fill="#252525"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M7.25 8C7.25 7.58579 7.58579 7.25 8 7.25H10.5C10.9142 7.25 11.25 7.58579 11.25 8C11.25 8.41421 10.9142 8.75 10.5 8.75H8C7.58579 8.75 7.25 8.41421 7.25 8Z"
                     fill="#252525"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M12.75 8C12.75 7.58579 13.0858 7.25 13.5 7.25H16C16.4142 7.25 16.75 7.58579 16.75 8C16.75 8.41421 16.4142 8.75 16 8.75H13.5C13.0858 8.75 12.75 8.41421 12.75 8Z"
                     fill="#252525"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M7.25 11C7.25 10.5858 7.58579 10.25 8 10.25H10.5C10.9142 10.25 11.25 10.5858 11.25 11C11.25 11.4142 10.9142 11.75 10.5 11.75H8C7.58579 11.75 7.25 11.4142 7.25 11Z"
                     fill="#252525"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M12.75 11C12.75 10.5858 13.0858 10.25 13.5 10.25H16C16.4142 10.25 16.75 10.5858 16.75 11C16.75 11.4142 16.4142 11.75 16 11.75H13.5C13.0858 11.75 12.75 11.4142 12.75 11Z"
                     fill="#252525"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M11.25 22V19H12.75V22H11.25Z"
                     fill="#252525"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M7.25 22C7.25 21.5858 7.58579 21.25 8 21.25H16C16.4142 21.25 16.75 21.5858 16.75 22C16.75 22.4142 16.4142 22.75 16 22.75H8C7.58579 22.75 7.25 22.4142 7.25 22Z"
                     fill="#252525"
                  />
               </svg>
            </button>
         </div>
      )
   }
}
