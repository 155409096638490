import React from 'react'
import { useDropzone } from 'react-dropzone'

import './DropFileInput.css'

import DropFileInputItem from './DropFileInputItem'

const DropFileInput = ({ files, onChange, onDelete }) => {
   const { getRootProps, getInputProps } = useDropzone({ 
      onDrop: (files) => onChange(files),
      noClick: false
   })

   return (
      <div className='drop-file-input-wrapper'>
         <div 
            className='drop-file-input' 
            {...getRootProps()}
         >
            <div className='drop-file-input__body'>
               <img src="/assets/controls/document-icon.svg" alt="" />
               <p className='drop-file-input__desctiption'>
                  <span>Выберите файл</span> или перетащите его сюда
               </p>
            </div>
            <input
               className='drop-file-input__input'
               {...getInputProps()}
            />
         </div>
         {files.length > 0 && (
            <ul className='drop-file-input__files-list'>
               {files.map(file => (
                  <li key={file.id} className='drop-file-input__files-list-item'>
                     <DropFileInputItem fileItem={file} onDelete={onDelete} />
                  </li>
               ))}
            </ul>
         )}
      </div>
   )
}

export default DropFileInput