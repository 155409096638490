import React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import '../ForgotPasswordPage/ForgotPasswordPage.css'

import routeNames from '../../utils/consts/routeNames'
import AuthLogo from '../../components/AuthLogo'

const ResetPasswordEmailSentPage = () => {
   const email = useLocation().state
   const navigate = useNavigate()

   const handleReturn = () => navigate(routeNames.FORGOT_PASSWORD)

   if (!email) return <Navigate to={routeNames.FORGOT_PASSWORD} />

   return (
      <div className="auth">
         <AuthLogo />
         <div className="login-card forgot-password-page__card">
            <div className="login-card-wrapper forgot-password-page__wrapper">
               <div className="forgot-password-page__header">
                  <div className="forgot-password-page__header-title">
                     <button
                        type="button"
                        className="forgot-password-page__return-btn"
                        onClick={handleReturn}
                     >
                        <img
                           src="/assets/controls/return-back-icon.svg"
                           alt=""
                        />
                     </button>
                     <h4 className="forgot-password-page__header-text">
                        Восстановление пароля
                     </h4>
                  </div>
                  <p className="forgot-password-page__description">
                     Для завершения процесса восстановления пароля перейдите по
                     ссылке, отправленной на почту{' '}
                     <span>{email}</span>
                  </p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ResetPasswordEmailSentPage