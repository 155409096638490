import React from 'react'

import './DropFileInputItem.css'

const DropFileInputItem = ({ fileItem, onDelete }) => {
   const { id, file } = fileItem
   const { name, size } = file
   const mbSize = (size / 1024 / 1024).toFixed(2) + ' MB'

   const handleDelete = () => onDelete(id)
   
   return (
      <div className='drop-file-input-item'>
         <button 
            className='drop-file-input-item__delete-btn'
            onClick={handleDelete}   
         >
            <img src="/assets/controls/close.svg" alt="" />
         </button>
         <img src="/assets/controls/document-icon.svg" alt="" />
         <p className='drop-file-input-item__name'>
            {name}
         </p>
         <p className='drop-file-input-item__size'>
            {mbSize}
         </p>
      </div>
   )
}

export default DropFileInputItem