import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../ForgotPasswordPage/ForgotPasswordPage.css'
import routeNames from '../../utils/consts/routeNames'
import AuthLogo from '../../components/AuthLogo'

const ResetPasswordTokenExpiredPage = () => {
   const navigate = useNavigate()

   const handleClick = () => navigate(routeNames.FORGOT_PASSWORD)

   return (
      <div className="auth">
         <AuthLogo />
         <div className="login-card forgot-password-page__card">
            <div className="login-card-wrapper forgot-password-page__wrapper">
               <div className="login-card-header-wrapper forgot-password-page__header-wrapper">
                  <h3
                     className="login-card-header-text forgot-password-page__header"
                  >
                     WEB MESSENGER
                  </h3>
               </div>
               <div className='forgot-password-page__description'>
                  <h4 className='forgot-password-page__description-header'>
                     Восстановление пароля
                  </h4>
                  <p className='forgot-password-page__description-text'>
                     C момента запроса на восстановление пароля прошло более 24 часов, поэтому ссылка неактивна. Пожалуйста, запросите смену пароля заново.
                  </p>
               </div>
               <button
                  type='button'
                  className='forgot-password-form__submit-btn'
                  onClick={handleClick}
               >
                  Восстановить
               </button>
            </div>
         </div>
      </div>
   )
}

export default ResetPasswordTokenExpiredPage